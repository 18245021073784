import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';
import '@bcg-web/bcg-react-datepicker/dist/bcg-react-datepicker.css';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FieldNamesMarkedBoolean, FormProvider, useForm } from 'react-hook-form';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AuthState, UserInfo } from '../../../custom';
import { Container } from '../../../Models/Container';
import { Template } from '../../../Models/Model';
import { Role, User } from '../../../Models/User';
import resources from '../../../resources.json';
import httpService from '../../../services/httpService';
import Loading from '../../routeHandler/Loading';
import Watchers from '../../watchers/Watchers';
import { Request } from '../Model';
import useErrorPages from '../useErrorPages';
import useTemplates from '../useTemplates';
import { PartnerSection } from './PartnerSection';
import styles from './RequestForm.module.css';

type PageProps = {
    userInfo: UserInfo;
    authContext: AuthState;
    user: User;
    request: Request;
    onRequestUpdate: (request: Request) => void;
};

function hasAnyValueChanged(dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<Request>>>) {
    const dirtyFieldNames = Object.keys(dirtyFields);
    if (dirtyFieldNames.length === 0) return false;
    if (dirtyFieldNames.length === 1 && dirtyFields.IsAccepted) return false;
    return true;
}

const RequestForm = ({ userInfo, authContext, user, request, onRequestUpdate }: PageProps) => {
    const navigate = useNavigate();
    const { templates } = useTemplates(authContext, handleTemplateLoading);
    const [allTemplates, setAllTemplates] = useState<Template[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [declineReason, setDeclineReason] = useState('');
    const [isDeclineEnabled, setIsDeclineEnabled] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allowChanges, setAllowChanges] = useState(true);
    const [adminPending, setAdminPending] = useState(false);
    const [decline, setDecline] = useState(false);
    const [disableAllButtons, setDisableAllButtons] = useState(false);
    const [isOffline, setOffline] = useState(false);
    const [isOfflineEdit, setOfflineEdit] = useState(false);
    const requestID = request.ID;
    const history = '/nrl/request/' + requestID + '/history';

    const methods = useForm<Request>({
        shouldUseNativeValidation: true,
        shouldFocusError: false,
        mode: 'onChange',
    });

    const { errors, dirtyFields, isSubmitting, isSubmitted } = methods.formState;
    const isFormInvalid = Object.keys(errors).length > 0;
    const { navigateToErrorPage } = useErrorPages();

    function handleTemplateLoading(value: boolean) {
        setIsLoading(isLoading || value);
    }

    const [messages, setMessages] = useState<Container[]>();

    useEffect(() => {
        (async () => {
            if (requestID) {
                setIsEditing(true);
            }
            await httpService
                .getMessages('INTERNALREQUEST')
                .then((response) => {
                    setMessages(response.data);
                })
                .catch((err) => {});
        })();
        return () => {};
    }, [requestID]);

    useEffect(() => {
        if (!templates || !templates.length) return;

        if (!request.ID) {
            setIsLoading(false);
            setAllTemplates([...templates]);
            request.Template = templates[0];
            methods.reset(request);
            return;
        }

        let activeTemplate = request.Template?.TemplateID
            ? templates.find(
                  (t) => t.TemplateID === request.Template.TemplateID || t.MasterID === request.Template.MasterID
              )
            : templates[0];

        if (!activeTemplate) {
            activeTemplate = {
                TemplateID: request.Template.TemplateID,
                Name: request.Template.Name,
                MasterID: request.Template.MasterID,
                IsPublished: false,
            };
            setAllTemplates([...templates, activeTemplate]);
        } else {
            setAllTemplates([...templates]);
        }
        setOfflineEdit(request.Offline ?? false);

        const status = request.Status;

        setAllowChanges(status === 'PENDING' && user?.Role !== 'COORDINATOR');
        if (status === 'PENDING' && user?.Role === 'ADMIN') {
            setAllowChanges(false);
            setAdminPending(true);
        }
        if (request.Status === 'FAILED') {
            setAllowChanges(true);
        }
        if (user.Role === 'VIEWER') {
            setAllowChanges(false);
        }

        methods.reset({
            ...request,
            Project: {
                ...request.Project,
                Code: request.Project.Code ?? '',
            },
            Template: activeTemplate,
            IsAccepted: request.IsAccepted && request.Status !== 'FAILED' && request.Status !== 'PENDING',
        });
        setIsLoading(false);
    }, [request, templates]);

    useEffect(() => {
        (async () => {
            if (requestType !== '') {
                await methods.handleSubmit(onSubmit)();
            }
        })();
    }, [requestType]);

    useEffect(() => {
        (async () => {
            if (decline) {
                setRequestType('decline');
            }
        })();
    }, [decline]);

    const declineMe = async () => {
        await httpService
            .declineRequest(requestID!, declineReason)
            .then(() => {
                navigate('/', { state: { status: 'closed' } });
            })
            .catch((err) => {
                navigateToErrorPage(err);
            });
    };

    const onInputCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setOffline(true);
        } else {
            setOffline(false);
        }
    };

    const onSubmit = async (data: Request) => {
        if (!isEditing) {
            setDisableAllButtons(true);
            await createRequest(userInfo, user, data, navigate, navigateToErrorPage);
        } else {
            if (requestType === '') return;
            setDisableAllButtons(true);
            if (requestType !== 'decline') {
                await updateRequest(request, data, requestID!, requestType, navigateToErrorPage, onRequestUpdate);
            }
            if (requestType === 'approve') {
                await httpService
                    .approveRequest(requestID!)
                    .then(() => {
                        navigate('/', { state: { status: 'open' } });
                    })
                    .catch((err) => {
                        navigateToErrorPage(err);
                    });
            } else if (requestType === 'decline') {
                await httpService
                    .declineRequest(requestID!, data.DeclineReason)
                    .then(() => {
                        navigate('/', { state: { status: 'closed' } });
                    })
                    .catch((err) => {
                        navigateToErrorPage(err);
                    });
            } else {
                setRequestType('');
            }
            setDisableAllButtons(false);
        }
    };

    const reasonChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDeclineReason(e.currentTarget.value.trim());
        setIsDeclineEnabled(e.currentTarget.value.trim() === '');
    };

    const handleApprove = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            setRequestType('approve');
        }
    };

    const handleSave = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            setRequestType('save');
        }
    };

    const handleDecline = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            setDecline(true);
        }
    };

    const handleModalDecline = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            setIsOpen(true);
        }
    };

    const isNotEmpty = (value: any) => {
        return value.trim() !== '';
    };

    const isNotDefaultLetter = (value: any) => {
        return value.trim() !== '-1';
    };

    const isAccepted = (value: any) => {
        return value === true;
    };

    return (
        <div>
            {isLoading && <Loading></Loading>}
            {isSubmitting && (
                <div className={styles['loading-overlay']}>
                    <Loading></Loading>
                </div>
            )}
            {!isLoading && (
                <div className={'container ' + styles.container}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/nrl">{resources.request.previousCrumb}</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {isEditing ? requestID : resources.request.new.breadcrumb}
                        </li>
                        {user?.Role === 'ADMIN' && requestID && (
                            <li className="breadcrumb-item">
                                <a href={history}>History</a>
                            </li>
                        )}
                    </ol>
                    <div className={styles.content}>
                        <FormProvider {...methods}>
                            <form
                                noValidate
                                onSubmit={methods.handleSubmit(onSubmit)}
                                className={isFormInvalid ? 'was-validated' : ''}
                            >
                                <div className={styles.requestHeaderSection}>
                                    <span className="lead">
                                        {isEditing ? resources.request.crumb + requestID : resources.request.new.title}
                                    </span>
                                    {((user?.Role === 'COORDINATOR' && (!request.Status || request.Status === '')) ||
                                        (user?.Role === 'PARTNER' &&
                                            (!request.Status ||
                                                request.Status === '' ||
                                                request.Status === 'PENDING'))) &&
                                        messages?.length &&
                                        messages.length > 0 && <span>{messages[0].Content}</span>}
                                    {(isOfflineEdit || !isEditing) && (
                                        <div>
                                            <span className={'form-check'}>
                                                <input
                                                    className="form-check-input"
                                                    onChange={onInputCheckboxChange}
                                                    disabled={
                                                        !allowChanges ||
                                                        decline ||
                                                        user?.Role === 'VIEWER' ||
                                                        request.Status === 'PENDING'
                                                    }
                                                    type="checkbox"
                                                    defaultChecked={request.Offline}
                                                    id="isOffline"
                                                />
                                                <label className="form-check-label" htmlFor={'isOffline'}>
                                                    {resources.request.new.offline}
                                                </label>
                                            </span>
                                            <label
                                                className={`form-check-label ${request.Status === 'OPEN' || request.Status === 'DECLINED' || request.Status === 'FAILED' || request.Status === 'CLOSED' || request.Status === 'PENDING' ? 'disableChek' : ''}`}
                                            >
                                                {resources.request.new.note}
                                            </label>
                                        </div>
                                    )}

                                    {/* </div> */}
                                </div>
                                <div className="d-flex flex-grow-1 flex-column gap-3 mt-4">
                                    <PartnerSection
                                        role={user.Role!}
                                        status={request.Status}
                                        allowChanges={allowChanges}
                                        decline={decline}
                                    />

                                    <div className="form-group flex-column d-flex gap-4">
                                        <div className={styles.formGroup}>
                                            <p>{resources.request.coordinator.title}</p>
                                            <div className={styles.inlineFormGroup}>
                                                <div className="form-group flex-grow-1">
                                                    <label className="form-label">
                                                        {resources.request.coordinator.firstName.label}
                                                    </label>
                                                    <input
                                                        readOnly={
                                                            request.Status === 'CLOSED' ||
                                                            request.Status === 'DECLINED' ||
                                                            user?.Role === 'VIEWER'
                                                        }
                                                        className={`form-control ${errors['Coordinator']?.FirstNames ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder={
                                                            resources.request.coordinator.firstName.placeholder
                                                        }
                                                        id="FirstNames"
                                                        {...methods.register('Coordinator.FirstNames', {
                                                            required: resources.request.coordinator.firstName.required,
                                                            validate: {
                                                                'Coordinator.FirstNames': (e) =>
                                                                    isNotEmpty(e) ||
                                                                    resources.request.coordinator.firstName.validation,
                                                            },
                                                            disabled:
                                                                decline ||
                                                                request.Status === 'DECLINED' ||
                                                                user?.Role === 'VIEWER' ||
                                                                (user?.Role === 'COORDINATOR' &&
                                                                    (request.Status === 'CLOSED' ||
                                                                        request.Status === 'OPEN' ||
                                                                        request.Status === 'FAILED' ||
                                                                        request.Status === 'PENDING')) ||
                                                                (user?.Role === 'ADMIN' &&
                                                                    request.Status !== 'OPEN' &&
                                                                    request.Status !== 'FAILED'),
                                                        })}
                                                    />
                                                    {errors['Coordinator']?.FirstNames && (
                                                        <div className="invalid-feedback">
                                                            <span>
                                                                {resources.request.coordinator.firstName.required}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group flex-grow-1">
                                                    <label className="form-label">
                                                        {resources.request.coordinator.lastName.label}
                                                    </label>
                                                    <input
                                                        readOnly={
                                                            request.Status === 'CLOSED' ||
                                                            request.Status === 'DECLINED' ||
                                                            user?.Role === 'VIEWER'
                                                        }
                                                        className={`form-control ${errors['Coordinator'] ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder={resources.request.coordinator.lastName.placeholder}
                                                        id="LastNames"
                                                        {...methods.register('Coordinator.LastNames', {
                                                            required: resources.request.coordinator.lastName.required,
                                                            validate: {
                                                                'Coordinator.LastNames': (e) =>
                                                                    isNotEmpty(e) ||
                                                                    resources.request.coordinator.lastName.validation,
                                                            },
                                                            disabled:
                                                                decline ||
                                                                request.Status === 'DECLINED' ||
                                                                user?.Role === 'VIEWER' ||
                                                                (user?.Role === 'COORDINATOR' &&
                                                                    (request.Status === 'CLOSED' ||
                                                                        request.Status === 'OPEN' ||
                                                                        request.Status === 'DECLINED' ||
                                                                        request.Status === 'FAILED' ||
                                                                        request.Status === 'PENDING')) ||
                                                                (user?.Role === 'ADMIN' &&
                                                                    request.Status !== 'OPEN' &&
                                                                    request.Status !== 'FAILED'),
                                                        })}
                                                    />
                                                    {errors['Coordinator']?.LastNames && (
                                                        <div className="invalid-feedback">
                                                            <span>
                                                                {resources.request.coordinator.lastName.required}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.inlineFormGroup}>
                                                <div className="flex-grow-1">
                                                    <label className="form-label">
                                                        {resources.request.coordinator.eMail.label}
                                                    </label>
                                                    <input
                                                        readOnly={
                                                            request.Status === 'CLOSED' ||
                                                            request.Status === 'DECLINED' ||
                                                            user?.Role === 'VIEWER'
                                                        }
                                                        className={`form-control ${errors['Coordinator']?.EMail ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder={resources.request.coordinator.eMail.placeholder}
                                                        id="Email"
                                                        {...methods.register('Coordinator.EMail', {
                                                            required: resources.request.coordinator.eMail.required,
                                                            pattern: {
                                                                value: /^\S+@\S+\.\S+$/,
                                                                message: resources.request.coordinator.eMail.validation,
                                                            },
                                                            disabled:
                                                                decline ||
                                                                request.Status === 'DECLINED' ||
                                                                user?.Role === 'VIEWER' ||
                                                                (user?.Role === 'COORDINATOR' &&
                                                                    (request.Status === 'CLOSED' ||
                                                                        request.Status === 'OPEN' ||
                                                                        request.Status === 'DECLINED' ||
                                                                        request.Status === 'FAILED' ||
                                                                        request.Status === 'PENDING')) ||
                                                                (user?.Role === 'ADMIN' &&
                                                                    request.Status !== 'OPEN' &&
                                                                    request.Status !== 'FAILED'),
                                                        })}
                                                    />
                                                    {errors['Coordinator']?.EMail && (
                                                        <div className="invalid-feedback">
                                                            <span>{resources.request.coordinator.eMail.required}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex-grow-1">
                                                    <label className="form-label">
                                                        {resources.request.coordinator.legalEntity.label}
                                                    </label>
                                                    <input
                                                        readOnly={
                                                            request.Status === 'CLOSED' ||
                                                            request.Status === 'DECLINED' ||
                                                            user?.Role === 'VIEWER'
                                                        }
                                                        className={`form-control ${errors['Coordinator']?.Company ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder={
                                                            resources.request.coordinator.legalEntity.placeholder
                                                        }
                                                        id="Company"
                                                        {...methods.register('Coordinator.Company', {
                                                            required:
                                                                resources.request.coordinator.legalEntity.required,
                                                            validate: {
                                                                'Coordinator.Company': (e) =>
                                                                    isNotEmpty(e) ||
                                                                    resources.request.coordinator.legalEntity
                                                                        .validation,
                                                            },
                                                            disabled:
                                                                decline ||
                                                                request.Status === 'DECLINED' ||
                                                                user?.Role === 'VIEWER' ||
                                                                (user?.Role === 'COORDINATOR' &&
                                                                    (request.Status === 'CLOSED' ||
                                                                        request.Status === 'OPEN' ||
                                                                        request.Status === 'DECLINED' ||
                                                                        request.Status === 'FAILED' ||
                                                                        request.Status === 'PENDING')) ||
                                                                (user?.Role === 'ADMIN' &&
                                                                    request.Status !== 'OPEN' &&
                                                                    request.Status !== 'FAILED'),
                                                        })}
                                                    />
                                                    {errors['Coordinator']?.Company && (
                                                        <div className="invalid-feedback">
                                                            <span>
                                                                {resources.request.coordinator.legalEntity.required}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.formGroup}>
                                            <div className={styles.inlineFormGroup}>
                                                <div className="flex-grow-1">
                                                    <label className="form-label">
                                                        {resources.request.project.transaction.label}
                                                    </label>
                                                    <input
                                                        className={`form-control ${errors['Project']?.Transaction ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder={resources.request.project.transaction.placeholder}
                                                        id="Transaction"
                                                        {...methods.register('Project.Transaction', {
                                                            required: resources.request.project.transaction.required,
                                                            validate: {
                                                                'Project.Transaction': (e) =>
                                                                    isNotEmpty(e) ||
                                                                    resources.request.project.transaction.validation,
                                                            },
                                                            disabled:
                                                                !allowChanges || decline || user?.Role === 'VIEWER',
                                                        })}
                                                    />
                                                    {errors['Project']?.Transaction && (
                                                        <div className="invalid-feedback">
                                                            <span>
                                                                {resources.request.project.transaction.required}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex-grow-1">
                                                    <label className="form-label">
                                                        {resources.request.project.caseCode.label}
                                                    </label>
                                                    <input
                                                        readOnly={
                                                            request.Status === 'CLOSED' ||
                                                            request.Status === 'DECLINED' ||
                                                            user?.Role === 'VIEWER' ||
                                                            isOfflineEdit
                                                        }
                                                        className={`form-control ${errors['Coordinator'] ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder={resources.request.project.caseCode.placeholder}
                                                        id="CaseCode"
                                                        {...methods.register('Project.Code', {
                                                            disabled:
                                                                request.Status === 'CLOSED' ||
                                                                request.Status === 'DECLINED' ||
                                                                decline ||
                                                                user?.Role === 'VIEWER' ||
                                                                isOfflineEdit,
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.formGroup}>
                                            <div className="flex-grow-1">
                                                <label className="form-label">
                                                    {resources.request.nrl.template.label}
                                                </label>
                                                <select
                                                    className={`form-control ${errors['Template']?.TemplateID ? 'is-invalid' : ''}`}
                                                    id="TemplateID"
                                                    {...methods.register('Template.TemplateID', {
                                                        required: resources.request.nrl.template.required,
                                                        validate: {
                                                            'Template.TemplateID': (e) =>
                                                                isNotDefaultLetter(e) ||
                                                                resources.request.nrl.template.validation,
                                                        },
                                                        disabled:
                                                            !allowChanges || decline || user?.Role === Role.VIEWER,
                                                        onChange(event) {
                                                            return event.target.value;
                                                        },
                                                    })}
                                                >
                                                    {allTemplates.map((template) => (
                                                        <option
                                                            key={template.TemplateID}
                                                            value={template.TemplateID}
                                                            disabled={!template.IsPublished}
                                                        >
                                                            {template.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors['Template']?.TemplateID && (
                                                    <div className="invalid-feedback">
                                                        <span>{resources.request.nrl.template.required}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {!isOfflineEdit && !isOffline && (
                                            <Watchers
                                                watchers={request.Watchers}
                                                isOfflineRequest={request.Offline ?? false}
                                                allowChange={true}
                                            />
                                        )}

                                        <div className={styles.formGroup}>
                                            <div className="form-check form-group mb-3">
                                                {allowChanges && (
                                                    <div>
                                                        <input
                                                            className={`form-check-input ${errors['IsAccepted'] ? 'is-invalid' : ''}`}
                                                            defaultChecked={
                                                                request.IsAccepted &&
                                                                request.Status !== 'FAILED' &&
                                                                request.Status !== 'PENDING'
                                                            }
                                                            type="checkbox"
                                                            id="IsAccepted"
                                                            {...methods.register('IsAccepted', {
                                                                validate: {
                                                                    isAccepted: (e) =>
                                                                        isAccepted(e) ||
                                                                        resources.request.responsibility.validation,
                                                                },
                                                                disabled:
                                                                    !allowChanges || decline || user?.Role === 'VIEWER',
                                                            })}
                                                            onChange={(e) => {
                                                                methods.setValue('IsAccepted', e.target.checked, {
                                                                    shouldDirty: false,
                                                                    shouldValidate:
                                                                        isSubmitted || hasAnyValueChanged(dirtyFields),
                                                                });
                                                            }}
                                                        />
                                                        <label className="form-check-label">
                                                            {resources.request.responsibility.label}
                                                        </label>
                                                        {errors['IsAccepted'] && (
                                                            <div className="invalid-feedback">
                                                                <span>{resources.request.responsibility.required}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {!allowChanges && (
                                                    <div>
                                                        <input
                                                            disabled
                                                            className={`form-check-input`}
                                                            defaultChecked={request.IsAccepted}
                                                            type="checkbox"
                                                            id="IsAccepted"
                                                        />
                                                        <label className="form-check-label">
                                                            {resources.request.responsibility.label}
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row request-button-row">
                                            <div className="col-md d-flex flex-row-reverse">
                                                {!isEditing && user?.Role !== 'COORDINATOR' && (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-sm btn-primary me-2"
                                                        disabled={disableAllButtons}
                                                    >
                                                        {resources.request.approve}
                                                    </button>
                                                )}
                                                {!isEditing && user?.Role === 'COORDINATOR' && (
                                                    <div>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary me-2"
                                                            disabled={disableAllButtons}
                                                        >
                                                            {resources.acknowledgement.submit}
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                navigate('/');
                                                            }}
                                                            className="btn btn-sm btn-primary me-2"
                                                            disabled={disableAllButtons}
                                                        >
                                                            {resources.acknowledgement.back}
                                                        </button>
                                                    </div>
                                                )}
                                                {isEditing && (
                                                    <div>
                                                        <button
                                                            type="button"
                                                            id="btnSave"
                                                            onClick={handleSave}
                                                            className={`btn btn-sm btn-primary me-2`}
                                                            disabled={
                                                                !hasAnyValueChanged(dirtyFields) || disableAllButtons
                                                            }
                                                        >
                                                            {resources.request.save}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row request-button-row">
                                            <div className="col-md d-flex flex-row-reverse">
                                                {((isEditing && allowChanges) || adminPending) && (
                                                    <div>
                                                        {user?.Role === 'PARTNER' && (
                                                            <div>
                                                                <button
                                                                    disabled={disableAllButtons}
                                                                    type="submit"
                                                                    onClick={handleApprove}
                                                                    className="btn btn-sm btn-primary me-2"
                                                                >
                                                                    {resources.request.approve}
                                                                </button>
                                                                <button
                                                                    disabled={disableAllButtons}
                                                                    type="submit"
                                                                    onClick={handleDecline}
                                                                    className="btn btn-sm btn-primary me-2"
                                                                >
                                                                    {resources.request.decline}
                                                                </button>
                                                            </div>
                                                        )}

                                                        {user?.Role === 'ADMIN' && (
                                                            <div>
                                                                <button
                                                                    disabled={disableAllButtons}
                                                                    type="submit"
                                                                    onClick={handleModalDecline}
                                                                    className="btn btn-sm btn-primary me-2"
                                                                >
                                                                    {resources.request.decline}
                                                                </button>
                                                                <Modal show={isOpen} onHide={() => setIsOpen(false)}>
                                                                    <Modal.Header>Confirmation</Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="col-md d-flex flex-row-reverse">
                                                                            <div className="col-md">
                                                                                <label className="form-label">
                                                                                    {
                                                                                        resources.request.declineReason
                                                                                            .label
                                                                                    }
                                                                                </label>
                                                                                <input
                                                                                    onChange={reasonChange}
                                                                                    className="form-control"
                                                                                    defaultValue={declineReason}
                                                                                    type="text"
                                                                                    placeholder={
                                                                                        resources.request.declineReason
                                                                                            .placeholder
                                                                                    }
                                                                                    id="DeclineReason"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <div className="row button-row">
                                                                            <button
                                                                                type="button"
                                                                                disabled={isDeclineEnabled}
                                                                                onClick={declineMe}
                                                                                className="btn btn-sm btn-primary me-2"
                                                                            >
                                                                                Confirm
                                                                            </button>
                                                                        </div>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                </div>
            )}
        </div>
    );
};

async function createRequest(
    userInfo: UserInfo,
    user: User,
    data: Request,
    navigate: NavigateFunction,
    navigateToErrorPage: (err: any) => void
) {
    const newRequest: Request = {
        Status: (document.getElementById('isOffline') as HTMLInputElement).checked ? 'OPEN' : 'NEW',
        Project: { Transaction: data.Project.Transaction, Code: data.Project.Code },
        Coordinator: {
            FirstNames: data.Coordinator.FirstNames,
            LastNames: data.Coordinator.LastNames,
            EMail: data.Coordinator.EMail,
            Company: data.Coordinator.Company,
        },
        Partner:
            user?.Role === 'COORDINATOR'
                ? { ...data.Partner, Role: 'PARTNER' }
                : {
                      Role: 'PARTNER',
                      EMail: userInfo?.email ?? '',
                      Name: `${userInfo?.given_name} ${userInfo?.family_name}`,
                      ID: 0,
                  },
        Template: { TemplateID: data.Template.TemplateID },
        IsAccepted: user?.Role === 'COORDINATOR' ? false : data.IsAccepted,
        Watchers: data.Watchers,
        Role: user?.Role,
        Offline: (document.getElementById('isOffline') as HTMLInputElement).checked,
    };
    await httpService
        .createRequest(newRequest)
        .then((response) => {
            navigate(
                '/',
                user?.Role === 'COORDINATOR' && !(document.getElementById('isOffline') as HTMLInputElement).checked
                    ? {
                          state: {
                              status: 'pending',
                              requestID: response.Content,
                              new: '',
                          },
                      }
                    : { state: { status: 'open', requestID: response.Content, new: 'NEWREQUEST' } }
            );
        })
        .catch((err) => {
            navigateToErrorPage(err);
        });
}

async function updateRequest(
    request: Request,
    data: Request,
    requestID: string,
    requestType: string,
    navigateToErrorPage: (err: any) => void,
    onRequestUpdate: (request: Request) => void
) {
    let tempID = document.getElementById('TemplateID') as HTMLSelectElement;
    let tempData: string = tempID.options[tempID.selectedIndex].value;

    const updatedRequest: Request = {
        ...request,
        ID: requestID!,
        Project: {
            Transaction:
                data.Project.Transaction === undefined ? request.Project.Transaction : data.Project.Transaction,
            Code: data.Project.Code === undefined ? request.Project.Code : data.Project.Code,
        },
        Coordinator: {
            FirstNames: data.Coordinator.FirstNames ?? request.Coordinator.FirstNames,
            LastNames: data.Coordinator.LastNames ?? request.Coordinator.LastNames,
            EMail: data.Coordinator.EMail ?? request.Coordinator.EMail,
            Company: data.Coordinator.Company ?? request.Coordinator.Company,
        },
        Template: { TemplateID: tempData },
        IsAccepted: data.IsAccepted,
        Watchers: data.Watchers,
    };

    if (request.Status === 'FAILED') {
        updatedRequest.Partner = { ...data.Partner, Role: 'PARTNER' };
    }

    if (requestType !== 'approve') {
        await httpService.saveRequest(updatedRequest).catch((err) => {
            navigateToErrorPage(err);
        });
        markWatchersAsNotNew(updatedRequest);
        onRequestUpdate(updatedRequest);
    }
}

function markWatchersAsNotNew(request: Request) {
    request.Watchers?.forEach((watcher) => {
        watcher.IsNew = false;
    });
}

export default RequestForm;
